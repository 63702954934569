import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  CheckCircleIcon,
  DocumentTextIcon,
  ListBulletIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/20/solid"; // Import an icon for the clipboard



import axios from "axios";
import EnquiryEditModal from "./EnquiryEditModal";

import ConfirmationModal from "./ConfirmationModal";
import EnquiryDialog from "./EnquiryDialog";

import NoteForm from "./NoteForm";
import NoteEditModal from "./NoteEditModal";
import AddPackageModal from "./AddPackageModal"; // Ensure there is no typo and the file extension (.js) is not included
import EditPackageModal from "./EditPackageModal"; // Adjust the path as necessary

import {
  UserCircleIcon,
  CalendarDaysIcon,
  CheckIcon,
  HomeIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import {
  HandThumbUpIcon,
  UserIcon,
  TagIcon,
  CalendarIcon,
} from "@heroicons/react/20/solid";

const labelColors = {
  urgent: "bg-red-100 text-red-800",
  high_priority: "bg-yellow-100 text-yellow-800",
  no_response: "bg-gray-100 text-gray-800",
  unlabelled: "bg-gray-100 text-gray-800",
};

const labels = [
  { name: "Unlabelled", value: null },
  { name: "Urgent", value: "urgent" },
  { name: "High Priority", value: "high_priority" },
  { name: "No Response", value: "no_response" },
];

const dueDates = [
  { name: "No due date", value: null },
  { name: "Today", value: new Date().toISOString() },
  {
    name: "Tomorrow",
    value: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const fetchTutorCruncherJobDetails = async (jobId) => {
//   try {
//     const response = await axios.get(`/api/tutorcruncher/job/${jobId}`);
//     return response.data;
//   } catch (error) {
//     console.error(`Error fetching job details for job ID ${jobId}:`, error.message);
//     return null;
//   }
// };

const tutorCruncherStatuses = ["prospect", "live", "dormant"];

const statuses = [
  "New Lead",
  "Contacted",
  "Job Posted",
  "Profile Sent",
  "Connected",
  "First Lesson Complete",
];
const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Kingdom",
  "United States",
  "United Arab Emirates",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
const customerTypes = ["Student", "Parent"];
const leadSources = ["Contact Form", "Phone", "Email"];
const howDidTheyHearAboutUsOptions = [
  "Not Specified",
  "Google Search",
  "Advertisement",
  "Blog Article",
  "Referred By Friend",
  "Social Media",
  "Study Resources",
  "Whatsapp",
  "Existing",
];
const budgets = [
  "< 30",
  "30 - 39",
  "40 - 49",
  "50 - 59",
  "60 - 69",
  "70 - 79",
  "80+",
  "Unrestricted Budget",
];
const schoolYears = [
  { value: "13-14", label: "13-14 (Year 9)" },
  { value: "14-15", label: "14-15 (Year 10)" },
  { value: "15-16", label: "15-16 (Year 11)" },
  { value: "16-17", label: "16-17 (Year 12)" },
  { value: "17-18", label: "17-18 (Year 13)" },
  { value: "University Student", label: "University Student" },
];


const ClientDetails = () => {
  
  const { clientId } = useParams();
  const [noteType, setNoteType] = useState("Note");

  

  // Toggle between 'Note' and 'Task'
  const toggleType = () => {
    setNoteType((prevType) => (prevType === "Note" ? "Task" : "Note"));
  };

  const [client, setClient] = useState(null);
  const [salesManager, setSalesManager] = useState(null);

  const [tutorCruncherId, setTutorCruncherId] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track loading state

const [enquiryIdToDelete, setEnquiryIdToDelete] = useState(null);  // Store the ID of the enquiry to delete


  const [enquiries, setEnquiries] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [enquiryData, setEnquiryData] = useState({
    client_first_name: "",
    client_last_name: "",
    client_email: "",
    student_first_name: "",
    student_last_name: "",
    student_email: ""
  });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [timeline, setTimeline] = useState([]);

  
  const [users, setUsers] = useState([]);

  const [jobStatus, setJobStatus] = useState("");
  const [editedClient, setEditedClient] = useState({});
  const [activeTab, setActiveTab] = useState("overview");

  const [isEnquiryDialogOpen, setIsEnquiryDialogOpen] = useState(false);

  const [editingNoteId, setEditingNoteId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [noteEditText, setNoteEditText] = useState("");
  const [clients, setClients] = useState([]); // For the EnquiryDialog

  const [client_manager, setClientManager] = useState(null);

  const [isAddPackageModalOpen, setIsAddPackageModalOpen] = useState(false);
  const [isDeletePackageModalOpen, setIsDeletePackageModalOpen] =
    useState(false);
  const [isEditPackageModalOpen, setIsEditPackageModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [packages, setPackages] = useState([]);

  const calculateRevenueCostProfit = (enquiry) => {
    let revenue = 0;
    let cost = 0;
    let profit = 0;

    // Default to 0 if fields are null
    const chargeRate = enquiry.charge_rate ?? 0;
    const tutorRate = enquiry.tutor_rate ?? 0;
    const totalLessonHoursComplete = enquiry.totalLessonHoursComplete ?? 0;

    // Perform the calculation only if the necessary data is available
    revenue = totalLessonHoursComplete * chargeRate;
    cost = totalLessonHoursComplete * tutorRate;
    profit = revenue - cost;

    return { revenue, cost, profit };
  };

  

  // Function to fetch enquiries and calculate values
  const fetchEnquiriesWithCalculatedValues = async () => {
    try {
      const response = await axios.get(`/api/clients/${clientId}/enquiries`);
      
      const updatedEnquiries = await Promise.all(
        response.data.map(async (enquiry) => {
          const { revenue, cost, profit } = calculateRevenueCostProfit(enquiry);
          let jobStatus = "N/A";
          if (enquiry.job_id) {
            // Fetch job status from backend for each enquiry that has a job_id
            const jobStatusResponse = await axios.get(`/api/tutorcruncher/job-status/${enquiry.job_id}`);
            jobStatus = jobStatusResponse.data.status;
          }
  
          return {
            ...enquiry,
            sales_manager: enquiry.sales_manager ?? client?.sales_manager,
            jobStatus,
            revenue,
            cost,
            profit,
          };
        })
      );
  
      setEnquiries(updatedEnquiries); // Update state with all calculated values and job statuses
    } catch (error) {
      console.error("Error fetching enquiries:", error.message);
    }
  };
  

  useEffect(() => {
    // Only run if the client is already fetched
    if (client) {
      const updatedEnquiries = enquiries.map((enquiry) => {
        if (!enquiry.sales_manager) {
          return {
            ...enquiry,
            sales_manager: client.sales_manager,
          };
        }
        return enquiry;
      });
  
      // Only set the state if there's an actual update to prevent infinite loop
      if (JSON.stringify(enquiries) !== JSON.stringify(updatedEnquiries)) {
        setEnquiries(updatedEnquiries);
      }
    }
  }, [client]);
  
  useEffect(() => {
    // Avoid refetching if we already have the client data
    if (clientId && !client) {
      fetchClientDetails();
    }
    // Same for enquiries - only fetch if we don't already have them
    if (clientId && enquiries.length === 0) {
      fetchEnquiriesWithCalculatedValues();
    }
  }, [clientId, client, enquiries]);
  

  // Function to trigger data synchronization for client jobs
  const syncClientJobsData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/sync-client-jobs/${clientId}`);
      console.log(response.data.message); // Show a success message
    } catch (err) {
      setError("Failed to sync job data. Please try again later.");
      console.error("Error syncing job data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Automatically sync data when the page loads
    syncClientJobsData();
  }, [clientId]);

  const [assigned, setAssigned] = useState(null);
  const [noteFormData, setNoteFormData] = useState({
    note_text: "",
    assigned: { name: "Unassigned", value: null },
    labelled: labels[0],
    dated: dueDates[0],
    type: "Note", // Default type is 'Note'
  });

  const [assignees, setAssignees] = useState([
    { name: "Unassigned", value: null },
  ]);
  const [labelled, setLabelled] = useState(labels[0]);
  const [dated, setDated] = useState(dueDates[0]);
  const [tutorCruncherStatus, setTutorCruncherStatus] = useState("");

  // const [tutorCruncherPipelineStage, setTutorCruncherPipelineStage] =
  //   useState("");

  const [loggedInUser, setLoggedInUser] = useState(null); // Store the current logged-in user

  const handleTutorCruncherStatusChangeOnJob = async (enquiryId, newStatus) => {
    try {
      // Call your backend endpoint to update the job status on TutorCruncher
      const response = await axios.put(`/api/tutorcruncher/jobs/${enquiryId}/status`, {
        status: newStatus,
      });
  
      if (response.status === 200) {
        // Update the local state to reflect the new status
        setEnquiries((prevEnquiries) =>
          prevEnquiries.map((enquiry) =>
            enquiry.id === enquiryId ? { ...enquiry, jobStatus: newStatus } : enquiry
          )
        );
      } else {
        console.error("Failed to update TutorCruncher job status:", response.data);
      }
    } catch (error) {
      console.error("Error updating TutorCruncher job status:", error.message);
    }
  };
  

  const handleOpenAddPackageModal = () => {
    setIsAddPackageModalOpen(true);
  };

  const handleCloseAddPackageModal = () => {
    setIsAddPackageModalOpen(false);
  };

  const handleSavePackage = async (packageData) => {
    try {
      const packageWithClientId = { ...packageData, client_id: clientId };
      const response = await axios.post("/api/packages", packageWithClientId);

      // Add the new package to the local state
      setPackages((prevPackages) => [...prevPackages, response.data]);

      // Fetch the updated list of packages
      fetchPackages();

      handleCloseAddPackageModal();
    } catch (error) {
      console.error("Error saving package:", error.message);
    }
  };

  const fetchPackages = async () => {
    try {
      const response = await axios.get(`/api/clients/${clientId}/packages`);
      const enquiriesResponse = await axios.get(
        `/api/clients/${clientId}/enquiries`
      );

      // Check response data for debugging
      console.log("Packages response:", response.data);
      console.log("Enquiries response:", enquiriesResponse.data);

      // Create a map of job IDs to their corresponding tutorCruncher IDs
      const jobIdToTutorCruncherIdMap = {};
      enquiriesResponse.data.forEach((enquiry) => {
        if (enquiry.id && enquiry.job_id) {
          jobIdToTutorCruncherIdMap[enquiry.id] = enquiry.job_id;
        }
      });

      // Map over the packages and add the corresponding tutorCruncher ID from enquiries
      const packagesData = response.data.map((pkg) => {
        const tutorcruncherId = jobIdToTutorCruncherIdMap[pkg.job_id] || "N/A";
        return {
          ...pkg,
          tutorcruncher_id: tutorcruncherId,
        };
      });

      console.log("Processed packages data:", packagesData);
      setPackages(packagesData);
    } catch (error) {
      console.error("Error fetching packages:", error.message);
    }
  };

  useEffect(() => {
    const syncJobsData = async () => {
      try {
        const response = await axios.get("/api/sync-jobs-data");
        console.log(response.data.message);
        fetchEnquiries(); // Refresh the enquiries after syncing
      } catch (error) {
        console.error("Error synchronizing jobs data:", error.message);
      }
    };

    syncJobsData();
  }, [clientId]);

  
  
  const handleEditPackage = (index) => {
    const packageToEdit = packages[index];
    setSelectedPackage(packageToEdit);
    setIsEditPackageModalOpen(true);
  };

  const confirmDeletePackage = (index) => {
    setSelectedPackage(packages[index]);
    setIsDeletePackageModalOpen(true);
  };

  const handleDeletePackage = async () => {
    try {
      // Send a delete request to the API using the package ID
      await axios.delete(`/api/packages/${selectedPackage.id}`);

      // Remove the package from the local state
      setPackages((prevPackages) =>
        prevPackages.filter((pkg) => pkg.id !== selectedPackage.id)
      );

      // Close the confirmation modal
      setIsDeletePackageModalOpen(false);
    } catch (error) {
      console.error("Error deleting package:", error.message);
    }
  };

  const handleSaveEditedPackage = async (updatedPackage) => {
    try {
      // Make a PUT request to update the package
      const response = await axios.put(
        `/api/packages/${selectedPackage.id}`,
        updatedPackage
      );

      // Update the package in the local state
      setPackages((prevPackages) =>
        prevPackages.map((pkg) =>
          pkg.id === selectedPackage.id ? response.data : pkg
        )
      );

      // Fetch the updated list of packages to refresh the table
      fetchPackages();

      setIsEditPackageModalOpen(false);
    } catch (error) {
      console.error("Error updating package:", error.message);
    }
  };

  const updateEnquiryStatus = async (enquiryId) => {
    try {
      await axios.put(`/api/enquiries/${enquiryId}`, {
        salesbook_status: "Won",
      });
      // Update local state
      setEnquiries((prevEnquiries) =>
        prevEnquiries.map((enquiry) =>
          enquiry.id === enquiryId
            ? { ...enquiry, salesbook_status: "Won" }
            : enquiry
        )
      );
    } catch (error) {
      console.error("Error updating enquiry status:", error.message);
    }
  };


  // Function to handle enquiry status change
const updateEnquiryStatusInline = (enquiryId, newStatus) => {
  fetch(`/api/enquiries/${enquiryId}/status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: newStatus }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to update enquiry status');
      }
      return response.json();
    })
    .then((data) => {
      // Handle successful status update by updating the local state
      setEnquiries((prevEnquiries) =>
        prevEnquiries.map((enquiry) =>
          enquiry.id === enquiryId
            ? { ...enquiry, status: newStatus }
            : enquiry
        )
      );
      
      // Set a flag in localStorage to indicate that the status was updated
      localStorage.setItem('enquiryStatusUpdated', 'true');
      
      console.log(`Enquiry ${enquiryId} status updated to ${newStatus}`, data);
    })
    .catch((error) => {
      console.error('Error updating enquiry status:', error);
    });
};

  


  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoggedInUser(response.data);
       // console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };

    fetchLoggedInUser();
  }, []);

  useEffect(() => {
    if (!loggedInUser) {
    //  console.log("No logged-in user found yet.");
      return; // Prevent running fetchEnquiries() before loggedInUser is available
    }

   // console.log("Logged in user is ready:", loggedInUser);
    fetchEnquiries();
    fetchUsers();
  }, [loggedInUser]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/users"); // Replace with your actual endpoint
      setUsers(response.data);
     // console.log("Users (Client Managers) fetched:", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const initiateDeleteClient = () => {
    // If there are notes on this client, show a separate modal
    if (timeline.length > 0) {
      setIsDeleteClientWithNotesModalOpen(true);
    } else {
      // No notes, directly confirm delete
      setIsDeleteClientModalOpen(true);
    }
  };

  const handleDeleteClient = async () => {
    try {
      await axios.delete(`/api/clients/${clientId}`);
      alert("Client deleted successfully.");
      navigate("/clients");
    } catch (error) {
      console.error(`Error deleting client with id ${clientId}:`, error.message);
      alert("Failed to delete client.");
    } finally {
      setIsDeleteClientModalOpen(false);
    }
  };

  const handleDeleteClientAndNotes = async () => {
    try {
      // First delete all notes
      await axios.delete(`/api/clients/${clientId}/notes`);

      // Now delete the client
      await axios.delete(`/api/clients/${clientId}`);
      alert("Client and all notes deleted successfully.");
      navigate("/clients");
    } catch (error) {
      console.error(`Error deleting client with id ${clientId}:`, error.message);
      alert("Failed to delete client and notes.");
    } finally {
      setIsDeleteClientWithNotesModalOpen(false);
    }
  };

  const handleDeleteClick = (enquiryId) => {
    console.log("Delete button clicked for enquiryId:", enquiryId);
    setEnquiryIdToDelete(enquiryId);
    setIsDeleteEnquiryModalOpen(true);
};

const handleDeleteEnquiry = async (enquiryId) => {
    try {
        console.log("Attempting to delete enquiry with ID:", enquiryId);
        
        // Make the API call to delete the enquiry
        const response = await axios.delete(`/api/enquiries/${enquiryId}`);
        
        // Log the response to check if the deletion was successful
        console.log("Delete response:", response);

        if (response.status === 200) {
            console.log("Enquiry deleted successfully, updating state.");

            // After successful deletion, update the state
            setEnquiries((prevEnquiries) => {
                const updatedEnquiries = prevEnquiries.filter((enquiry) => enquiry.id !== enquiryId);
                console.log("Updated enquiries list after deletion:", updatedEnquiries);
                return updatedEnquiries;
            });
        } else {
            console.error("Failed to delete enquiry, status:", response.status);
        }
    } catch (error) {
        console.error("Error deleting enquiry:", error.message);
    }
};

  
  
  
  
  
 

  const handleMarkAsDone = async (noteId) => {
    try {
      const response = await axios.put(
        `/api/clients/${clientId}/notes/${noteId}/status`,
        { status: "Done" }
      );

      // Update the timeline to reflect the new status in the frontend
      setTimeline((prevTimeline) =>
        prevTimeline.map((note) =>
          note.id === noteId ? { ...note, status: "Done" } : note
        )
      );
    } catch (error) {
      console.error("Error marking task as done:", error.message);
    }
  };

  const handleEditNote = (note) => {
    setEditingNoteId(note.id);
    setNoteFormData({
      note_text: note.content || "",
      assigned: note.assigned || assignees[0], // Ensure this is correctly set
      labelled: note.labelled || labels[0],
      dated: note.dated || dueDates[0],
      noteType: note.type || "Note", // Ensure the correct type is passed
    });
    setShowEditModal(true);
  };

  const handleEditEnquiry = (enquiryId) => {
    const enquiry = enquiries.find((e) => e.id === enquiryId);
    if (enquiry) {
      setSelectedEnquiry(enquiry);
      setIsEditModalOpen(true);
    }
  };


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log(`${text} copied to clipboard!`);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleSaveEditedEnquiryAndSubmit = async (updatedEnquiry) => {
    setIsSubmitting(true);
  
    try {
      // Save the updated enquiry details
      const savedEnquiryResponse = await axios.put(
        `/api/clients/${clientId}/enquiries/${updatedEnquiry.id}`,
        updatedEnquiry
      );
      const savedEnquiry = savedEnquiryResponse.data;
  
      // Prepare data for submission to TutorCruncher
      const clientData = {
        user: {
          first_name: client.first_name,
          last_name: client.last_name,
          email: client.email,
          mobile: client.phone,
        },
        status: "live",
        send_emails: true,
      };
  
      console.log("Saved Enquiry Data:", savedEnquiry);
  
      // Check if student email is available, otherwise fallback to client email
   
  
      const studentEmail = savedEnquiry.student_email || client.email;

      const studentData = {
        first_name: savedEnquiry.student_first_name,
        last_name: savedEnquiry.student_last_name,
       // email: savedEnquiry.student_email || client.email, // Assuming client is defined and contains the email
      };
      
      
      
      console.log("Student Data before sending to TutorCruncher:", studentData);
      
      // Pass this studentData to TutorCruncher
      
  
      const jobData = {
        name: savedEnquiry.job_title,
        description: savedEnquiry.job_description,
        charge_rate: savedEnquiry.charge_rate,
        contractor_rate: savedEnquiry.tutor_rate,
        job_status: updatedEnquiry.status, // Use updatedEnquiry.status from the form
      };
      
      
      
  
      // Submit to TutorCruncher
      const tutorCruncherResponse = await axios.post(
        "/api/tutorcruncher/add-client-job",
        {
          clientData,
          studentData,
          jobData,
        }
      );
  
      const { serviceId } = tutorCruncherResponse.data;
  
      // Update local enquiry state with the job ID
      const updatedEnquiryWithJobId = {
        ...savedEnquiry,
        job_id: serviceId,
        status: "Job Posted",
      };
  
      // Update the local state
      setEnquiries((prevEnquiries) =>
        prevEnquiries.map((enquiry) =>
          enquiry.id === updatedEnquiry.id ? updatedEnquiryWithJobId : enquiry
        )
      );
  
      // Fetch updated enquiries to reflect the change in tabs
      fetchEnquiries();
  
      setIsEditModalOpen(false); // Close modal on success
    } catch (error) {
      console.error("Error converting enquiry:", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleSalesManagerChangeOnEnquiry = async (enquiryId, selectedManager) => {
    try {
      // Optimistically update the sales manager in the state
      setEnquiries((prevEnquiries) =>
        prevEnquiries.map((enquiry) =>
          enquiry.id === enquiryId
            ? { ...enquiry, sales_manager: selectedManager }
            : enquiry
        )
      );
  
      // Send the updated sales manager to the backend
      const response = await axios.put(`/api/clients/${clientId}/enquiries/${enquiryId}`, {
        sales_manager: selectedManager,
      });
  
      if (response.status === 200) {
        // Re-fetch the data to make sure everything is up to date, including job status
        await fetchEnquiriesWithCalculatedValues();
      } else {
        console.error("Failed to update sales manager:", response.data);
        // Optionally revert state if the update fails
        fetchEnquiriesWithCalculatedValues();
      }
    } catch (error) {
      console.error("Error updating sales manager on enquiry:", error.message);
      // Re-fetch to ensure state is correct
      fetchEnquiriesWithCalculatedValues();
    }
  };
  
  
  
  
  const handleAddToTutorCruncher = (enquiryId) => {
    // Find the selected enquiry to edit
    const enquiry = enquiries.find((e) => e.id === enquiryId);
    if (!enquiry) return;

    // Set the selected enquiry in the state
    setSelectedEnquiry(enquiry);

    // Open the Enquiry Edit Modal
    setIsEditModalOpen(true); // This will open the modal before submitting to TutorCruncher
  };

  const confirmDeleteEnquiry = (enquiryId) => {
    if (!enquiryId) {
      console.error("No enquiryId provided to confirmDeleteEnquiry");
      return;
    }
  
    console.log("Enquiry ID to delete:", enquiryId);  // Log the enquiryId passed
    setSelectedEnquiryId(enquiryId);  // Set selectedEnquiryId before opening the modal
    setIsDeleteEnquiryModalOpen(true);  // Open the confirmation modal
  };
  
  
  
  
  
  const [hasUnpaidInvoice, setHasUnpaidInvoice] = useState(false);

  


  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [isDeleteClientWithNotesModalOpen, setIsDeleteClientWithNotesModalOpen] = useState(false);


  const [isDeleteEnquiryModalOpen, setIsDeleteEnquiryModalOpen] =
    useState(false);

  const handleSaveEditedEnquiry = async (updatedEnquiry) => {
    try {
      const response = await axios.put(
        `/api/clients/${clientId}/enquiries/${updatedEnquiry.id}`,
        updatedEnquiry
      );

      // Ensure the data is correctly updated in the state
      setEnquiries((prevEnquiries) =>
        prevEnquiries.map((enquiry) =>
          enquiry.id === updatedEnquiry.id
            ? { ...enquiry, ...response.data } // Update with the latest data from the server
            : enquiry
        )
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error saving enquiry:", error.message);
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchClientDetails();
    }
  }, [clientId]);
  
  useEffect(() => {
    if (clientId && enquiries.length === 0) {
      fetchEnquiriesWithCalculatedValues();
    }
  }, [clientId]);
  

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api/users");
        const users = response.data.map((user) => ({
          name: user.email,
          value: user.email,
        }));
        setAssignees([{ name: "Unassigned", value: null }, ...users]);
      } catch (error) {
        setError("Failed to fetch users");
      }
    };

    fetchUsers();
  }, []);

  const handleSalesManagerChange = async (e) => {
    const selectedManagerId = e.target.value;
  
    try {
      // Preserve the current client_manager
      const currentClientManager = client_manager || client?.client_manager;
  
      const response = await axios.put(`/api/clients/${clientId}`, {
        sales_manager: selectedManagerId,
        client_manager: currentClientManager, // Always send the current client_manager if it's available
      });
  
      if (response.status === 200) {
        await handleManagerChangeLog("Sales Manager", client?.sales_manager, selectedManagerId);
        await fetchClientDetails(); // Refresh the client data to reflect changes
      } else {
        console.error("Failed to update sales manager:", response.data);
      }
    } catch (error) {
      console.error("Error assigning sales manager:", error.message);
    }
  };
  
  const handleManagerChange = async (e) => {
    const selectedManagerId = e.target.value;
  
    try {
      // Preserve the current sales_manager
      const currentSalesManager = salesManager || client?.sales_manager;
  
      const response = await axios.put(`/api/clients/${clientId}`, {
        client_manager: selectedManagerId,
        sales_manager: currentSalesManager, // Always send the current sales_manager if it's available
      });
  
      if (response.status === 200) {
        await handleManagerChangeLog("Client Manager", client?.client_manager, selectedManagerId);
        await fetchClientDetails(); // Refresh the client data to reflect changes
      } else {
        console.error("Failed to update client manager:", response.data);
      }
    } catch (error) {
      console.error("Error assigning client manager:", error.message);
    }
  };
  
  

  const handleManagerChangeLog = async (managerType, oldManager, newManager) => {
    if (oldManager !== newManager) {
      const createdUser = loggedInUser?.email || "Unknown User";
      await handleAddNoteSystem(
        `${managerType} changed from ${oldManager || "None"} to ${newManager || "None"}`,
        createdUser
      );
    }
  };
  
  
  

  useEffect(() => {
    fetchClientDetails();
    fetchEnquiries();
    fetchClients();
    fetchPackages(); // Add this line to ensure packages are fetched
  }, [clientId]);

  const handleSaveEnquiry = async (enquiry) => {
    try {
      const response = await axios.post(
        `/api/clients/${enquiry.client_id}/enquiries`,
        enquiry
      );
      fetchEnquiries(); // Refresh the enquiries list after successful addition
    } catch (error) {
      console.error("Error saving enquiry:", error.message);
    }
  };

  const openEnquiryDialog = () => {
    setIsEnquiryDialogOpen(true);
  };

  const closeEnquiryDialog = () => {
    setIsEnquiryDialogOpen(false);
  };

 

  const fetchClientDetails = async () => {
    try {
      const response = await axios.get(`/api/clients/${clientId}`);
      console.log("Updated client details:", response.data);
  
      setClient(response.data);
      setSalesManager(response.data.sales_manager); // Set the sales manager state
  
      setClientManager(response.data.client_manager);
  
      if (response.data.job_id) {
        // Fetch Job Status from your backend
        const jobResponse = await axios.get(
          `/api/tutorcruncher/status/${response.data.job_id}`
        );
        setJobStatus(jobResponse.data.status);
      }
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching client details:", error.message);
      setError("Failed to fetch client details");
      setLoading(false);
    }
  };
  

  const fetchJobStatus = async (jobId) => {
    try {
      const response = await axios.get(
        `/api/tutorcruncher/job-status/${jobId}`
      );
      return response.data.status; // Assuming status is part of the response
    } catch (error) {
      console.error(
        `Error fetching job status for job ID ${jobId}:`,
        error.message
      );
      return "N/A"; // Return N/A if the job status cannot be fetched
    }
  };

  const fetchEnquiries = async () => {
    try {
      const response = await axios.get(`/api/clients/${clientId}/enquiries`);
      const enquiries = response.data;

      // Fetch job status for each enquiry that has a job_id
      const enquiriesWithStatus = await Promise.all(
        enquiries.map(async (enquiry) => {
          if (enquiry.job_id) {
            const jobStatus = await fetchJobStatus(enquiry.job_id); // Fetch job status from TutorCruncher
            return { ...enquiry, jobStatus }; // Attach the job status to the enquiry object
          }
          return enquiry;
        })
      );

      setEnquiries(enquiriesWithStatus); // Update state with enquiries including job statuses
    } catch (error) {
      console.error("Error fetching enquiries:", error.message);
    }
  };

  const shouldDisplaySalesManager = false; // Set to true if you want to display it

  const fetchClients = async () => {
    try {
      const response = await axios.get(`/api/clients`);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients:", error.message);
    }
  };

  // Filter enquiries into jobs and non-jobs
  const enquiriesNotInTutorCruncher = enquiries.filter(
    (enquiry) => !enquiry.tutorcruncher_id
  );

  const enquiriesInTutorCruncher = enquiries.filter(
    (enquiry) => enquiry.tutorcruncher_id
  );

  const enquiriesNotInTutorCruncherTable = enquiries.filter(
    (enquiry) => !enquiry.job_id
  );

  const enquiriesInTutorCruncherTable = enquiries.filter(
    (enquiry) => enquiry.job_id
  );

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get(`/api/clients/${clientId}/notes`);
        const formattedNotes = response.data.map((note) => ({
          id: note.id,
          content: note.note_text,
          date: new Date(note.created_at).toLocaleDateString(),
          status: note.status, // Make sure the status is saved here
          datetime: note.created_at,
          icon: note.type === "Task" ? ListBulletIcon : DocumentTextIcon,
          iconBackground:
            note.type === "Task" ? "bg-orange-400" : "bg-blue-400",
          assigned: assignees.find((a) => a.value === note.assigned_user) || {
            name: "Unassigned",
            value: null,
          },
          labelled: labels.find((l) => l.value === note.label),
          dated: note.due_date
            ? {
                name: new Date(note.due_date).toLocaleDateString(),
                value: note.due_date,
              }
            : null,
          type: note.type || "Note",
          created_user: note.created_user || "Unknown",
        }));
        setTimeline(formattedNotes);
      } catch (error) {
        console.error("Error fetching notes:", error.message);
        setError("Failed to fetch notes");
      }
    };

    fetchNotes();
  }, [clientId, assignees]);

  const handleSaveEditedNote = async (noteId, noteData) => {
    try {
      const dataToSend = {
        note_text: noteData.note_text || "",
        assigned_user: noteData.assigned?.value || null,
        label: noteData.labelled?.value || null,
        due_date: noteData.dated?.value
          ? new Date(noteData.dated.value).toISOString()
          : null,
      };

      const response = await axios.put(
        `/api/clients/${clientId}/notes/${noteId}`,
        dataToSend
      );

      setTimeline((prevTimeline) =>
        prevTimeline.map((note) =>
          note.id === noteId
            ? {
                ...note,
                content: noteData.note_text,
                assigned: noteData.assigned,
                labelled: noteData.labelled,
                dated: noteData.dated,
                date: new Date(response.data.updated_at).toLocaleDateString(),
                datetime: response.data.updated_at,
              }
            : note
        )
      );
      setEditingNoteId(null);
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating note:", error.message);
      setError("Failed to update note");
    }
  };

  // Use this function in your Pipeline.js where necessary

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axios.get(`/api/clients/${clientId}`);
        setClient(response.data);
        setEditedClient(response.data);
  
        if (response.data.tutorcruncher_id) {
          const tutorCruncherResponse = await axios.get(
            `/api/tutorcruncher/status/${response.data.tutorcruncher_id}`
          );
          const tutorCruncherData = tutorCruncherResponse.data;
  
          // Set the tutorCruncherStatus
          setTutorCruncherStatus(tutorCruncherData.status);
  
          // Now also update the client state with the invoice_balance fetched from TutorCruncher
          setClient((prevClient) => ({
            ...prevClient,
            invoice_balance: tutorCruncherData.invoice_balance,
          }));
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching client details:", error.message);
        setError("Failed to fetch client details");
        setLoading(false);
      }
    };
  
    fetchClientDetails();
  }, [clientId]);
  

  const handleCancelEdit = () => {
    setEditingNoteId(null);
    setNoteEditText("");
    setShowEditModal(false);
  };

  // Delete a note
  const handleDeleteNote = async (noteId) => {
    try {
      await axios.delete(`/api/clients/${clientId}/notes/${noteId}`);
      setTimeline((prevTimeline) =>
        prevTimeline.filter((note) => note.id !== noteId)
      );
    } catch (error) {
      console.error("Error deleting note:", error.message);
      setError("Failed to delete note");
    }
  };

  const handleUpdateTutorCruncherStatus = async (newStatus) => {
    try {
      await axios.put(`/api/clients/${clientId}`, {
        tutorCruncherStatus: newStatus,
      });
      setClient((prevClient) => ({
        ...prevClient,
        tutorCruncherStatus: newStatus,
      }));
    } catch (error) {
      console.error("Error updating TutorCruncher status:", error.message);
      setError("Failed to update TutorCruncher status");
    }
  };

  const JobStatusLabel = ({ status }) => {
    const getStatusStyle = (status) => {
      switch (status) {
        case "pending":
          return "bg-gray-100 text-gray-800";
        case "available":
          return "bg-orange-100 text-orange-800";
        case "in-progress":
          return "bg-blue-100 text-blue-800";
        case "finished":
          return "bg-green-100 text-green-800";
        case "gone-cold":
          return "bg-red-100 text-red-800";
        default:
          return "bg-gray-100 text-gray-800";
      }
    };

    return (
      <span
        className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${getStatusStyle(
          status
        )}`}
      >
        {status}
      </span>
    );
  };

  const handleAddNote = async (noteData) => {
    if (!noteData.note_text.trim()) {
      console.log("Note text is empty");
      return;
    }

    const dataToSend = {
      note_text: noteData.note_text,
      assigned_user: noteData.assigned.value,
      label: noteData.labelled.value,
      due_date: noteData.dated.value,
      type: noteData.type || "Note", // Ensure 'Note' is the default type
      created_user: loggedInUser?.email || "Unknown", // Add the logged-in user as the creator
    };

    try {
      const response = await axios.post(
        `/api/clients/${clientId}/notes`,
        dataToSend
      );

      setTimeline((prevTimeline) => [
        {
          id: response.data.id,
          content: response.data.note_text, // Correctly setting content
          date: new Date(response.data.created_at).toLocaleDateString(),
          type: response.data.type, // Set the type from the response
          datetime: response.data.created_at,
          icon: DocumentTextIcon,
          iconBackground: "bg-gray-400",
          assigned: noteData.assigned,
          labelled: noteData.labelled,
          dated: noteData.dated,
          created_user: response.data.created_user, // Include the created user
        },
        ...prevTimeline,
      ]);
    } catch (error) {
      console.error("Error adding note:", error.message);
      setError("Failed to add note");
    }
  };

  const handleAddNoteSystem = async (note_text, created_user) => {
    const dataToSend = { note_text, created_user };
  
    try {
      const response = await axios.post(
        `/api/clients/${clientId}/notes`,
        dataToSend
      );
  
      setTimeline((prevTimeline) => [
        {
          id: response.data.id,
          content: response.data.note_text,
          date: new Date(response.data.created_at).toLocaleDateString(),
          datetime: response.data.created_at,
          icon: UserIcon,
          iconBackground: "bg-gray-400",
          created_user: created_user,
        },
        ...prevTimeline,
      ]);
    } catch (error) {
      console.error("Error adding system note:", error.message);
      setError("Failed to add system note");
    }
  };
  

  const handleTutorCruncherStatusChange = async (status) => {
    try {
      const response = await axios.post("/update-tutorcruncher-status", {
        email: client.email,
        lastName: client.last_name,
        status,
      });
      setTutorCruncherStatus(status);
      console.log("TutorCruncher status updated:", response.data);

      // Add a system note for status change
      await handleAddNoteSystem(`TutorCruncher status changed to ${status}`);
    } catch (error) {
      console.error("Error updating TutorCruncher status:", error.message);
      setError("Failed to update TutorCruncher status");
    }
  };

  const handleSave = async () => {
    try {
      // Gather all fields from the editedClient object
      const updatedClient = {
        first_name: editedClient.first_name,
        last_name: editedClient.last_name,
        phone: editedClient.phone,
        email: editedClient.email,
        country: editedClient.country, // Ensure country is included
        customer_type: editedClient.customer_type, // Ensure customer_type is included
        form_host: editedClient.form_host,
        gclid: editedClient.gclid,
        lead_source: editedClient.lead_source,
        how_did_they_hear_about_us: editedClient.how_did_they_hear_about_us,
        budget: editedClient.budget,
        approx_hours: editedClient.approx_hours,
        school_year: editedClient.school_year,
        lead_status: editedClient.lead_status,
        // Add other fields as necessary
      };

      if (tutorCruncherId) {
        // Update TutorCruncher client details (excluding email)
        const tutorCruncherClientData = {
          user: {
            first_name: updatedClient.first_name,
            last_name: updatedClient.last_name,
            phone: updatedClient.phone, // Skip email
          },
        };
        await axios.post(
          `/api/tutorcruncher/update-client`,
          tutorCruncherClientData
        );
      }

      // Save changes to local client details (with email, if not linked to TutorCruncher)
      if (!tutorCruncherId) {
        const response = await axios.put(
          `/api/clients/${clientId}/details`,
          updatedClient
        );
        setClient(response.data);
      } else {
        // Save changes excluding email for TutorCruncher-linked clients
        const { email, ...fieldsWithoutEmail } = updatedClient;
        const response = await axios.put(
          `/api/clients/${clientId}/details`,
          fieldsWithoutEmail
        );
        setClient(response.data);
      }

      // Close the modal after successful save
      setEditing(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error updating client details:", error);
    }
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false); // or the relevant state you want to update
  };

  const handleStatusChange = async (status) => {
    try {
      const updatedClient = { ...client, lead_status: status };
      await axios.put(`/api/clients/${clientId}`, updatedClient);
      setClient(updatedClient);
    } catch (error) {
      console.error("Error updating client status:", error);
      setError("Failed to update client status");
    }
  };

  
 // Just before rendering invoice status badges
 let invoiceBalance = 0;
if (client && client.invoice_balance) {
  invoiceBalance = parseFloat(client.invoice_balance);
}

 

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axios.get(`/api/clients/${clientId}`);
        console.log("Client details response data:", response.data); // Log the response data
        setClient(response.data);
        setEditedClient(response.data);

        if (response.data.tutorcruncher_id) {
          // Fetch TutorCruncher status and pipeline stage
          const tutorCruncherResponse = await axios.get(
            `/api/tutorcruncher/status/${response.data.tutorcruncher_id}`
          );
          setTutorCruncherId(response.data.tutorcruncher_id);
          setTutorCruncherStatus(tutorCruncherResponse.data.status);
          // Set additional state for pipeline stage if needed
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching client details:", error.message);
        setError("Failed to fetch client details");
        setLoading(false);
      }
    };

    fetchClientDetails();
  }, [clientId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!client) {
    return <div>No client data found</div>;
  }

  return (
    <div className="border-b border-gray-900/10 pb-12">
      <nav aria-label="Breadcrumb" className="flex">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 flex-shrink-0 text-gray-400"
              />
              <a
                href="/"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Pipeline
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 flex-shrink-0 text-gray-400"
              />
              <span className="ml-4 text-sm font-medium text-gray-500">
                Client Details
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <header className="relative isolate pt-16 ">
        <div className="pb-12">
          <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div className="flex items-center gap-x-6">
              <UserCircleIcon className="h-16 w-16 text-gray-500" />
              <h1>
                <div className="text-sm leading-6 text-gray-500">
                  {tutorCruncherId ? (
                    <a
                      href={`https://app.tutorchase.com/clients/${tutorCruncherId}`}
                      className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
                    >
                      TutorCruncher ID #{tutorCruncherId}
                    </a>
                  ) : (
                    <button
                      onClick={handleAddToTutorCruncher}
                      className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                    >
                      Convert an enquiry to a job to add this client to
                      TutorCruncher
                    </button>
                  )}
                </div>
                {loading && <p>Loading job and appointment data...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="mt-1 text-base font-semibold leading-6 text-gray-900 flex items-center gap-2">
                 
{client.first_name} {client.last_name}

{invoiceBalance < 0 ? (
  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10">
    Unpaid Invoice £{Math.abs(invoiceBalance).toFixed(2)}
  </span>
) : (
  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10">
    No Unpaid Invoices
  </span>
)}
                </div>
              </h1>
            </div>

            <div className="flex items-center gap-x-4 sm:gap-x-6">
              {["Admin", "Assistant"].includes(loggedInUser?.role) && (
                <>
                  <div className="mt-2 text-sm">
                    <label className="font-semibold">Client Manager:</label>
                    <select
                      value={client_manager || ""}
                      onChange={handleManagerChange}
                      className="ml-2 border-gray-300 rounded-md"
                    >
                      <option value="">Assign Manager</option>
                      {assignees.map((user) => (
                        <option key={user.value} value={user.value}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {shouldDisplaySalesManager && (
          <div className="mt-2 text-sm">
            <label className="font-semibold">Sales Manager:</label>
            <select
              value={client.sales_manager || ""}
              onChange={handleSalesManagerChange}
              className="ml-2 border-gray-300 rounded-md"
            >
              <option value="">Assign Sales Manager</option>
              {assignees.map((user) => (
                <option key={user.value} value={user.value}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
        )}


{loggedInUser?.role !== "Assistant" && (
  <button
    onClick={initiateDeleteClient} // Use the function that checks for notes
    className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
  >
    Delete Client
  </button>
)}


                  {/* Confirmation modal if no notes */}
      <ConfirmationModal
        isOpen={isDeleteClientModalOpen}
        onClose={() => setIsDeleteClientModalOpen(false)}
        onConfirm={handleDeleteClient}
        title="Delete Client"
        message="Are you sure you want to delete this client? This action cannot be undone."
      />

      {/* Confirmation modal if there are notes */}
      <ConfirmationModal
        isOpen={isDeleteClientWithNotesModalOpen}
        onClose={() => setIsDeleteClientWithNotesModalOpen(false)}
        onConfirm={handleDeleteClientAndNotes}
        title="Delete Client and Notes"
        message="There are notes associated with this client. Do you want to delete the client AND all the notes?"
      />


<ConfirmationModal
    isOpen={isDeleteEnquiryModalOpen}
    onClose={() => {
        console.log("Delete modal closed without confirming deletion.");
        setIsDeleteEnquiryModalOpen(false);
    }}
    onConfirm={async () => {
        console.log("Delete confirmed for enquiryId:", enquiryIdToDelete);
        await handleDeleteEnquiry(enquiryIdToDelete);
        setIsDeleteEnquiryModalOpen(false);
    }}
    title="Delete Enquiry"
    message="Are you sure you want to delete this enquiry? This action cannot be undone."
/>


                </>
              )}

              {editing ? (
                <button
                  onClick={handleSave}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              ) : (
                loggedInUser?.role !== "Assistant" && (
                  <button
                    onClick={() => setEditing(true)}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Edit Client Details
                  </button>
                )
              )}

              <button
                onClick={openEnquiryDialog}
                className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Add New Enquiry
              </button>

              <button
                onClick={handleOpenAddPackageModal}
                className="rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Add Package
              </button>

              <AddPackageModal
                isOpen={isAddPackageModalOpen}
                onClose={handleCloseAddPackageModal}
                onSave={handleSavePackage}
                enquiries={enquiriesInTutorCruncherTable} // Pass only enquiries with job IDs
                loggedInUser={loggedInUser}
                updateEnquiryStatus={updateEnquiryStatus}
              />

              {/* <ConfirmationModal
                isOpen={isDeleteClientModalOpen}
                onClose={() => setIsDeleteClientModalOpen(false)}
                onConfirm={handleDeleteClient}
                title="Delete Client"
                message="Are you sure you want to delete this client? This action cannot be undone."
              /> */}
            </div>
          </div>
        </div>
      </header>

      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          <button
            onClick={() => setActiveTab("overview")}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === "overview"
                ? "border-indigo-600 text-indigo-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
          >
            Client Overview
          </button>
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <button
                onClick={() => setActiveTab("enquiries")}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === "enquiries"
                    ? "border-indigo-600 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                Enquiries ({enquiriesNotInTutorCruncherTable.length})
              </button>

              <button
                onClick={() => setActiveTab("jobs")}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === "jobs"
                    ? "border-indigo-600 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                Jobs ({enquiriesInTutorCruncherTable.length})
              </button>
              <button
                onClick={() => setActiveTab("packages")}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === "packages"
                    ? "border-indigo-600 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                Packages ({packages.length}){" "}
                {/* Use the correct state variable */}
              </button>
            </nav>
          </div>

          <button
            onClick={() => setActiveTab("notes")}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === "notes"
                ? "border-indigo-600 text-indigo-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
          >
            Notes & Tasks
          </button>
        </nav>
      </div>

      {activeTab === "overview" && (
        <div className="px-4 py-16 sm:px-6 lg:px-0 pb-12">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="lg:col-start-3 lg:row-end-1">
              <h2 className="sr-only">Summary</h2>
              <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                <dl className="flex flex-wrap">
                  <div className="flex-auto pl-6 pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">
                      Client
                    </dt>
                    <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                      {client.first_name} {client.last_name}
                    </dd>
                  </div>
                  <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <UserCircleIcon
                        aria-hidden="true"
                        className="h-6 w-5 text-gray-400"
                      />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">
                      {client.email}
                    </dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Phone</span>
                      <CalendarDaysIcon
                        aria-hidden="true"
                        className="h-6 w-5 text-gray-400"
                      />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      {client.phone}
                    </dd>
                  </div>
                </dl>
                <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                  {tutorCruncherId && (
                    <div>
                      <label
                        htmlFor="tutorCruncherStatus"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        TutorCruncher Status
                      </label>
                      <select
                        id="tutorCruncherStatus"
                        name="tutorCruncherStatus"
                        value={tutorCruncherStatus}
                        onChange={(e) =>
                          handleTutorCruncherStatusChange(e.target.value)
                        }
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        {tutorCruncherStatuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-8 xl:pb-8 xl:pt-8">
              <h2 className="text-base font-semibold leading-6 text-gray-900">
                Client Information
             
              <ClipboardIcon
                    onClick={() => copyToClipboard(`${client.first_name} - ${client.email} - ${client.phone}`)} // Copy all relevant info on click
                    className="inline-block ml-2 h-5 w-5 text-gray-300 cursor-pointer"
                  />
                   </h2>
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4">
                  <dt className="font-semibold text-gray-900">First Name</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <input
                        type="text"
                        value={editedClient.first_name}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            first_name: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.first_name
                    )}
                  </dd>
                </div>
                <div className="mt-2 sm:mt-0 sm:pl-4">
                  <dt className="font-semibold text-gray-900">Last Name</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <input
                        type="text"
                        value={editedClient.last_name}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            last_name: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.last_name
                    )}
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Email</dt>
                  <span className="text-sm text-gray-300">
                    *Email cannot be changed when linked to TutorCruncher due to
                    API limitations. They are working on the ability to update
                    client email addresses where the ID is the unique key
                    instead of the email.
                  </span>

                  <dd className="mt-2 text-gray-500">
                    {editing && !tutorCruncherId ? (
                      <input
                        type="email"
                        value={editedClient.email}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            email: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.email
                    )}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">Phone</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <input
                        type="text"
                        value={editedClient.phone}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            phone: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.phone
                    )}
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Country</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <select
                        value={editedClient.country}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            country: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    ) : (
                      client.country
                    )}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">Customer Type</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <select
                        value={editedClient.customer_type}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            customer_type: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        {customerTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    ) : (
                      client.customer_type
                    )}
                  </dd>
                </div>

                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Form Host</dt>
                  <dd className="mt-2 text-gray-500 break-words">
                    {editing ? (
                      <input
                        type="text"
                        value={editedClient.form_host}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            form_host: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.form_host
                    )}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">GCLID</dt>
                  <dd className="mt-2 text-gray-500 break-words">
                    {editing ? (
                      <input
                        type="text"
                        value={editedClient.gclid}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            gclid: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.gclid
                    )}
                  </dd>
                </div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Lead Source</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <select
                        value={editedClient.lead_source}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            lead_source: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        {leadSources.map((source) => (
                          <option key={source} value={source}>
                            {source}
                          </option>
                        ))}
                      </select>
                    ) : (
                      client.lead_source
                    )}
                  </dd>
                </div>

                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">
                    How did they hear about us
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <select
                        value={editedClient.how_did_they_hear_about_us}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            how_did_they_hear_about_us: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        {howDidTheyHearAboutUsOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      client.how_did_they_hear_about_us
                    )}
                  </dd>
                </div>

                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">Budget</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <select
                        value={editedClient.budget}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            budget: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        {budgets.map((budget) => (
                          <option key={budget} value={budget}>
                            {budget}
                          </option>
                        ))}
                      </select>
                    ) : (
                      client.budget
                    )}
                  </dd>
                </div>

                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">Approx Hours</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <input
                        type="text"
                        value={editedClient.approx_hours}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            approx_hours: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      client.approx_hours
                    )}
                  </dd>
                </div>

                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">School Year</dt>
                  <dd className="mt-2 text-gray-500">
                    {editing ? (
                      <select
                        value={editedClient.school_year}
                        onChange={(e) =>
                          setEditedClient({
                            ...editedClient,
                            school_year: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        {schoolYears.map((year) => (
                          <option key={year.value} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      client.school_year
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <EnquiryEditModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleSaveEditedEnquiryAndSubmit} // Call this after saving the enquiry
          enquiry={selectedEnquiry}
          setEnquiryData={setSelectedEnquiry}
          clientFirstName={client?.first_name}
        clientLastName={client?.last_name}
        clientEmail={client?.email}
        />
      )}

      <EnquiryDialog
        isOpen={isEnquiryDialogOpen}
        closeModal={closeEnquiryDialog}
        saveEnquiry={handleSaveEnquiry}
        clients={clients}
        clientFirstName={client.first_name}
        clientLastName={client.last_name}
        clientEmail={client.email}
      />

      {activeTab === "enquiries" && (
        <div className="px-4 py-8 sm:px-6 lg:px-0">
          <h2 className="text-base font-semibold leading-6 text-gray-900 mb-4">
            Enquiries
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Enquiry Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sales Manager
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Job Title
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Hourly Budget
                  </th>

                 
      {/* Limit the width of the Frequency column */}
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-72 max-w-xs">
        Frequency
      </th>

      {/* Limit the width of the Details column */}
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-72 max-w-xs">
        Details
      </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Convert
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created At
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {enquiriesNotInTutorCruncherTable.map((enquiry) => (
                  <tr key={enquiry.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {/* Dropdown for changing enquiry status */}
        <select
          value={enquiry.status}
          onChange={(e) => updateEnquiryStatusInline(enquiry.id, e.target.value)}
          className="text-sm border rounded px-2 py-1"
        >
          <option value="New Enquiry">New Enquiry</option>
          <option value="Contacted">Contacted</option>
          <option value="Searching For Tutor">Searching for Tutor</option>
          <option value="Profile Sent">Profile Sent</option>
          {/* Add other status options as needed */}
        </select>
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {/* Dropdown for changing sales manager */}
        <select
          value={enquiry.sales_manager || ""}
          onChange={(e) => handleSalesManagerChangeOnEnquiry(enquiry.id, e.target.value)}
          className="text-sm border rounded px-2 py-1"
        >
          <option value="">Assign Sales Manager</option>
          {assignees.map((user) => (
            <option key={user.value} value={user.value}>
              {user.name}
            </option>
          ))}
        </select>
      </td>
                  
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.job_title}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900 w-72 max-w-xs">
                      {enquiry.hourly_budget}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900 w-72 max-w-xs">
                      {enquiry.frequency_duration}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900 w-72 max-w-xs">
                      {enquiry.details}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <button
                        onClick={() => handleAddToTutorCruncher(enquiry.id)}
                        className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                      >
                        Convert
                      </button>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(enquiry.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <>
                        {!enquiry.job_id && (
                          <button
                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                            onClick={() => handleEditEnquiry(enquiry.id)}
                          >
                            Edit
                          </button>
                        )}
               
  <button
    className="text-red-600 hover:text-red-900"
    onClick={() => {
      setEnquiryIdToDelete(enquiry.id);  // Store the ID of the enquiry to delete
      setIsDeleteEnquiryModalOpen(true);  // Open the confirmation modal
    }}
  >
    Delete
  </button>




<ConfirmationModal
  isOpen={isDeleteEnquiryModalOpen}
  onClose={() => setIsDeleteEnquiryModalOpen(false)}  // Close the modal on cancel
  onConfirm={async () => {
    await handleDeleteEnquiry(enquiryIdToDelete);  // Pass the ID of the enquiry to delete
    setIsDeleteEnquiryModalOpen(false);  // Close the modal after deletion
  }}
  title="Delete Enquiry"
  message="Are you sure you want to delete this enquiry? This action cannot be undone."
/>

                      </>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activeTab === "jobs" && (
        <div className="px-4 py-8 sm:px-6 lg:px-0">
          <h2 className="text-base font-semibold leading-6 text-gray-900 mb-4">
            Jobs
          </h2>

        

          <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 table-fixed">

              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Job Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sales Manager
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Job ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Job Title
                  </th>
                  {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Subject
              </th> */}
             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[400px]">
  Job Description
</th>


                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Charge Rate
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tutor Rate
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Student
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Lessons Complete
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Lessons Hours Complete
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Stage
                  </th>
                 
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Approx Hours
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Approx Budget
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    School Year
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    How did they hear about us?
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created At
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {enquiriesInTutorCruncherTable.map((enquiry) => (
                  <tr key={enquiry.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <select
  value={enquiry.jobStatus || ""}
  onChange={(e) => handleTutorCruncherStatusChangeOnJob(enquiry.id, e.target.value)}
  className="text-sm border rounded px-2 py-1"
>
  <option value="">Select Status</option>
  <option value="available">Available</option>
  <option value="pending">Pending</option>
  <option value="in-progress">In-Progress</option>
  <option value="gone-cold">Gone Cold</option>
  <option value="finished">Finished</option>
</select>

</td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {/* Dropdown for changing sales manager */}
        <select
          value={enquiry.sales_manager || ""}
          onChange={(e) => handleSalesManagerChangeOnEnquiry(enquiry.id, e.target.value)}
          className="text-sm border rounded px-2 py-1"
        >
          <option value="">Assign Sales Manager</option>
          {assignees.map((user) => (
            <option key={user.value} value={user.value}>
              {user.name}
            </option>
          ))}
        </select>
      </td>
                  

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
  {enquiry.job_id ? (
    <a
      href={`https://app.tutorchase.com/cal/service/${enquiry.job_id}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:underline"
    >
      {enquiry.job_id}
    </a>
  ) : (
    <button
      onClick={() => handleAddToTutorCruncher(enquiry.id)}
      className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
    >
      Convert
    </button>
  )}
</td>


                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.job_title}
                    </td>

                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {enquiry.subject}
                </td> */}
<td className="px-6 py-4 text-sm text-gray-900 w-[400px] break-words">
  {enquiry.job_description}
</td>



                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      £{enquiry.charge_rate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      £{enquiry.tutor_rate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.student_first_name} {enquiry.student_last_name}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.totallessonscomplete}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.totallessonhourscomplete}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <span
                        className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
                          enquiry.stage === "No Lessons Complete"
                            ? "bg-red-100 text-red-800" // Red for "No Lessons Complete"
                            : enquiry.stage === "First Lesson Complete"
                            ? "bg-blue-100 text-green-800" // Blue for "First Lesson Complete"
                            : enquiry.stage === "2 Lessons Complete"
                            ? "bg-purple-100 text-purple-800" // Purple for "2 Lessons Complete"
                            : enquiry.stage === "3 Lessons Complete"
                            ? "bg-yellow-100 text-yellow-800" // Yellow for "3 Lessons Complete"
                            : enquiry.stage === "5 Lessons Complete"
                            ? "bg-orange-100 text-orange-800" // Orange for "5 Lessons Complete"
                            : enquiry.stage === "10 Lessons Complete"
                            ? "bg-green-100 text-green-800" // Green for "10 Lessons Complete"
                            : enquiry.stage === "20 Lessons Complete"
                            ? "bg-indigo-100 text-indigo-800" // Indigo for "20 Lessons Complete"
                            : "bg-gray-100 text-gray-800" // Default if none match
                        }`}
                      >
                        {enquiry.stage}
                      </span>
                    </td>

                  

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.approx_hours}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.budget}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.school_year}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {enquiry.how_did_they_hear_about_us}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {new Date(enquiry.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <>
                        {!enquiry.job_id && (
                          <button
                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                            onClick={() => handleEditEnquiry(enquiry.id)}
                          >
                            Edit
                          </button>
                        )}
          <button
  className="text-red-600 hover:text-red-900"
  onClick={() => {
    setEnquiryIdToDelete(enquiry.id);  // Set the ID of the enquiry to delete
    setIsDeleteEnquiryModalOpen(true); // Open the confirmation modal
  }}
>
  Delete
</button>



<ConfirmationModal
  isOpen={isDeleteEnquiryModalOpen}
  onClose={() => setIsDeleteEnquiryModalOpen(false)}  // Close the modal on cancel
  onConfirm={async () => {
    await handleDeleteEnquiry(enquiryIdToDelete);  // Call delete function with the selected enquiry ID
    setIsDeleteEnquiryModalOpen(false);  // Close the modal after deletion
  }}
  title="Delete Enquiry"
  message="Are you sure you want to delete this enquiry? This action cannot be undone."
/>

                      </>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activeTab === "packages" && (
        <div className="px-4 py-8 sm:px-6 lg:px-0">
          <h2 className="text-base font-semibold leading-6 text-gray-900 mb-4">
            Packages
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Lessons
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Lesson Amount (£)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Cost (£)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Assigned Job
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {packages.map((pkg, index) => {
                  // Log each package object to inspect its properties
                  console.log(`Rendering package at index ${index}:`, pkg);

                  return (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {pkg.total_lessons !== undefined
                          ? pkg.total_lessons
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        £
                        {pkg.lesson_amount !== undefined
                          ? pkg.lesson_amount
                          : "0.00"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        £
                        {pkg.total_cost !== undefined ? pkg.total_cost : "0.00"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {pkg.tutorcruncher_id !== undefined
                          ? pkg.tutorcruncher_id
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                          onClick={() => handleEditPackage(index)}
                        >
                          Edit
                        </button>
                        <button
                          className="text-red-600 hover:text-red-900"
                          onClick={() => confirmDeletePackage(index)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Confirmation Modal for Deleting Package */}
          <ConfirmationModal
            isOpen={isDeletePackageModalOpen}
            onClose={() => setIsDeletePackageModalOpen(false)}
            onConfirm={handleDeletePackage}
            title="Delete Package"
            message="Are you sure you want to delete this package? This action cannot be undone."
          />

          {/* Edit Package Modal (if needed) */}
          {isEditPackageModalOpen && (
            <EditPackageModal
              isOpen={isEditPackageModalOpen}
              onClose={() => setIsEditPackageModalOpen(false)}
              onSave={handleSaveEditedPackage}
              package={selectedPackage} // Ensure this is passed correctly
              enquiries={enquiriesInTutorCruncherTable} // If needed for the job selector
            />
          )}
        </div>
      )}

      {activeTab === "notes" && (
        <div>
       
          <div className="toolbar mb-4 mt-4">
              <h2 className="text-base font-semibold leading-6 text-gray-900 mb-4 mt-4">
                Add {noteType}
              </h2>
              {/* Add the toggle button */}
              <button
                type="button"
                onClick={toggleType}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none"
              >
                {noteType === "Note" ? "Switch to Task" : "Switch to Note"}
              </button>
            </div>

            <NoteForm
            onSave={handleAddNote}
            initialData={noteFormData}
            note_text={noteFormData.note_text}
            setNoteText={(text) =>
              setNoteFormData((prev) => ({ ...prev, note_text: text }))
            }
            assigned={noteFormData.assigned}
            setAssigned={(assigned) =>
              setNoteFormData((prev) => ({ ...prev, assigned }))
            }
            labelled={noteFormData.labelled}
            setLabelled={(labelled) =>
              setNoteFormData((prev) => ({ ...prev, labelled }))
            }
            dated={noteFormData.dated}
            setDated={(dated) =>
              setNoteFormData((prev) => ({ ...prev, dated }))
            }
            assignees={assignees}
            noteType={noteType || "Note"} // Ensure noteType is always passed
          />

          <div className="flow-root">
            {timeline.length > 0 ? (
              <ul role="list" className="-mb-8">
                {timeline.map((event, eventIdx) => (
                  <li key={event.id}>
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span
                          aria-hidden="true"
                          className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          {event.icon && (
                            <span
                              className={classNames(
                                event.iconBackground,
                                "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white"
                              )}
                            >
                              <event.icon
                                aria-hidden="true"
                                className="h-5 w-5 text-white"
                              />
                            </span>
                          )}
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-gray-900 font-medium">
                              {event.content}
                            </p>

                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                              Created by: {event.created_user || "Unknown"}
                            </span>

                            {/* Conditionally render the assignee badge */}
                            {event.type === "Task" && event.assigned && (
                              <div className="flex items-center mt-1">
                                <UserCircleIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                  Assignee: {event.assigned.name}
                                </span>
                              </div>
                            )}

                            {/* Conditionally render the label badge */}
                            {event.type === "Task" && event.labelled && (
                              <div className="flex items-center mt-1">
                                <TagIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span
                                  className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                    labelColors[event.labelled.value] ||
                                    "bg-gray-100 text-gray-800"
                                  }`}
                                >
                                  {event.labelled.name}
                                </span>
                              </div>
                            )}

                            {/* Conditionally render the due date badge */}
                            {event.type === "Task" && event.dated?.value && (
                              <div className="flex items-center mt-1">
                                <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                  {new Date(
                                    event.dated.value
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.datetime}>{event.date}</time>
                          </div>

                          {event.type === "Task" && (
                            <button
                              onClick={() => handleMarkAsDone(event.id)}
                              className={`inline-flex items-center px-2 py-1 rounded-md ${
                                event.status === "Done"
                                  ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                                  : "bg-green-50 text-green-700"
                              } text-xs font-medium`}
                              disabled={event.status === "Done"}
                            >
                              {event.status === "Done" ? (
                                <>
                                  <CheckCircleIcon className="h-4 w-4 mr-1" />
                                  Done
                                </>
                              ) : (
                                <>
                                  <CheckCircleIcon className="h-4 w-4 mr-1" />
                                  Mark as Done
                                </>
                              )}
                            </button>
                          )}

                          <button
                            onClick={() => handleEditNote(event)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteNote(event.id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-gray-500">Nothing here...</div>
            )}

             
            
          </div>

          

          {showEditModal && (
            <NoteEditModal
              isOpen={showEditModal}
              onClose={handleCancelEdit}
              onSave={(noteData) =>
                handleSaveEditedNote(editingNoteId, noteData)
              }
              note={noteFormData}
              setNoteText={(text) =>
                setNoteFormData((prev) => ({ ...prev, note_text: text }))
              }
              setAssigned={(assigned) =>
                setNoteFormData((prev) => ({ ...prev, assigned }))
              }
              setLabelled={(labelled) =>
                setNoteFormData((prev) => ({ ...prev, labelled }))
              }
              setDated={(dated) =>
                setNoteFormData((prev) => ({ ...prev, dated }))
              }
              assignees={assignees}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ClientDetails;
