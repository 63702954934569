import React, { useState, useEffect } from "react";
import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LicenseInfo } from '@mui/x-license';


import Pipeline from "./components/Pipeline";
import Tutors from "./components/Tutors";
import Users from "./components/Users";
import ProposalBuilder from "./components/ProposalBuilder";
import ClientDetails from "./components/ClientDetails";
import ClientList from "./components/ClientList";
import Leaderboard from "./components/Leaderboard";
import SalesBook from "./components/SalesBook"; // Ensure this path is correct
import Commissions from "./components/Commissions"; // Ensure this path is correct
import Login from "./components/Login";
import Logout from './components/Logout';  // <-- Import the Logout component
import PrivateRoute from "./PrivateRoute";
import Layout from "./Layout"; // Import the Layout component
import NotesTab from "./components/NotesTab"; // Import the NotesTab component
import UserProfile from "./components/UserProfile"; // Import the NotesTab component






import {
  ArchiveBoxArrowDownIcon,
  DocumentTextIcon,
  BuildingLibraryIcon,
  UserCircleIcon,
  UsersIcon,
  BookOpenIcon,
  CurrencyDollarIcon,
  TagIcon,
  StarIcon,
} from "@heroicons/react/20/solid";

LicenseInfo.setLicenseKey('397110379bbdb9d8dcd4b7e2a9e5533dTz05OTQ3NSxFPTE3NTk5MDg0MDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');


const navigation = [
  { name: "Pipeline", href: "/", icon: ArchiveBoxArrowDownIcon, current: true },
  {
    name: "Sales Book",
    href: "/sales-book",
    icon: BookOpenIcon,
    current: false,
  },
  {
    name: "Leaderboard",
    href: "/leaderboard",
    icon: StarIcon,
    current: false,
  },
  {
    name: "Commissions",
    href: "/commissions",
    icon: CurrencyDollarIcon,
    current: false,
  },
 
  // {
  //   name: "My Tasks", // Add the Notes tab here
  //   href: "/notes",
  //   icon: TagIcon, // Use an appropriate icon
  //   current: false,
  // },
  
  { name: "Customers", href: "/clients", icon: UsersIcon, current: false }, // Add this line
  // Add this line
  // {
  //   name: "Proposal Builder",
  //   href: "proposal-builder",
  //   icon: DocumentTextIcon,
  //   current: false,
  // },
  // {
  //   name: "Tutors",
  //   href: "/tutors",
  //   icon: BuildingLibraryIcon,
  //   current: false,
  // },
  { name: "Users", href: "/users", icon: UserCircleIcon, current: false },
 
];

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("Dashboard");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);  // User role
  const location = useLocation(); // Get the current route


  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      fetchCurrentUser(); // Fetch the user's role
    }
  }, []);

  useEffect(() => {
    // Update navigation items to reflect the current path
    navigation.forEach((item) => {
      item.current = item.href === location.pathname;
    });
  }, [location]);


  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.get('/api/current-user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserRole(response.data.role);  // Set the user's role
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login");
  };

  const handleNavigationClick = (page) => {
    setCurrentPage(page);
    navigation.forEach((item) => (item.current = item.name === page));
  };

  return (
    <div>
      {isAuthenticated ? (
        <Layout
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          handleLogout={handleLogout}
          handleNavigationClick={handleNavigationClick}
          navigation={navigation}
          userRole={userRole} // Pass userRole to Layout

        >
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Pipeline />
                </PrivateRoute>
              }
            />
              <Route
              path="/notes" // Add the route for Notes
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <NotesTab />
                </PrivateRoute>
              }
            />
            <Route
              path="/tutors"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Tutors />
                </PrivateRoute>
              }
            />
            
           <Route
  path="/users"
  element={
    ["Admin", "Assistant"].includes(userRole) ? (  // Check if the role is Admin or Assistant
      <PrivateRoute isAuthenticated={isAuthenticated}>
        <Users />
      </PrivateRoute>
    ) : (
      <div>Access Denied - Refresh page if logged in as an admin</div>  // Show an "Access Denied" message or handle it as you see fit
    )
  }
/>

            <Route
              path="/proposal-builder"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ProposalBuilder />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients/:clientId"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ClientDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ClientList />
                </PrivateRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Leaderboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/commissions"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Commissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-book"
              element={
               
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <SalesBook />
                  </PrivateRoute>
              
              }
            />
            <Route
              path="/profile"
              element={
               
                  <UserProfile isAuthenticated={isAuthenticated}>
                    <SalesBook />
                  </UserProfile>
              
              }
            />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
           <Route
            path="/logout"
            element={<Logout setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          
        </Routes>
      )}
    </div>
  );
};

export default App;
